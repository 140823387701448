    var swiper = new Swiper(".CardBlock__Swiper", {
        loop: true,
        loopFillGroupWithBlank: true,

        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: "auto",
                spaceBetween: 24,
            },

            992: {
                slidesPerView: 2,
                spaceBetween: 32,
            },


        }
    });
