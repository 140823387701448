// Gather all the shades
document.querySelectorAll('.shade-copy').forEach(function(shade) {

    // Add a listener
    shade.addEventListener('click', (event) => {

        // Get the colour
        let colourValue = shade.textContent.trim();

        // Copy the text
        navigator.clipboard.
            writeText(colourValue)
            .then(() => {
                console.log(colourValue + " copied");
            })
            .catch(() => {
                console.log(colourValue + " could not be copied");
            });
    })
})